import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import "focus-visible/dist/focus-visible.js"

// Components
import Hero from "../../components/hero"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
// import Stat from "../../components/stat"

// Assets

const Tos = ({ location }) => {
  const translation = {
    href: "/fr/cgu",
    hrefLang: "fr",
  }

  const data = useStaticQuery(graphql`
    query {
      heroBg: file(
        relativePath: { eq: "hero-bg.jpg" }
        sourceInstanceName: { eq: "images" }
      ) {
        relativePath
        childImageSharp {
          fluid(maxWidth: 2000, maxHeight: 700, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  const heroImage = data.heroBg.childImageSharp

  return (
    <Layout lang="en" location={location} translation={translation}>
      <SEO title="Terms and conditions | ABLV" description="" />

      {/* Hero */}
      <Hero
        uptitle="ABLV Group"
        title="Terms and Conditions of Usage"
        image={heroImage}
      />

      <section className="legal">
        {/* Columns */}
        <div className="c-container">
          <div className="grid grid-cols-12 gap-4 lg:gap-8">
            {/* Column */}
            <div className="col-span-12 lg:max-w-none lg:col-span-8">
              <p>
                <b>This content is currently only available in French.</b>
              </p>
              <p>Dernière mise à jour : 30 septembre 2022</p>
              <h2>1. Introduction</h2>
              <p>
                ABLV Group SA, dont le siège est à St-Sulpice (ci-après&nbsp;:
                «&nbsp;<b>ABLV Group</b>&nbsp;», «&nbsp;<b>nous</b>&nbsp;»,
                «&nbsp;<b>notre</b>&nbsp;», «&nbsp;<b>nos</b>&nbsp;»), édite et
                administre le site web www.ablv.swiss / (ci-après : «&nbsp;
                <b>Site</b>&nbsp;»).
              </p>
              <p>
                Les présentes Conditions Générales d’Utilisation (ci-après :
                «&nbsp;<b>CGU</b>&nbsp;») régissent les conditions auxquelles
                nous proposons l’accès au Site et la fourniture d’informations
                sur le Site.
              </p>
              <h2>2. Acceptation des CGU</h2>
              <p>
                En accédant au Site, vous reconnaissez avoir lu, compris et
                accepté d’être sujet à toutes les conditions prévues par les
                CGU.
              </p>
              <h2>3. Modification des CGU</h2>
              <p>
                Nous nous réservons le droit de modifier à tout moment tout ou
                partie des présentes CGU. Les nouvelles CGU seront disponibles
                sur le Site. Vous êtes responsable de vérifier régulièrement les
                CGU et de vous tenir informé de tout changement. Votre
                utilisation continue du Site après une modification constitue
                une acceptation des modifications.
              </p>
              <h2>4. Contenu</h2>
              <p>
                Le Site vise notamment à proposer du contenu relatif à la
                présentation de la Société, respectivement de ses services.
              </p>
              <p>
                Toutes les informations contenues sur le Site sont susceptibles
                d’être modifiées à tout moment, sans que cela puisse engager
                notre responsabilité.
              </p>
              <h2>5. Protection des données</h2>
              <p>
                En utilisant le Site, vous fournissez certaines données
                personnelles vous concernant. Nous nous engageons à les
                collecter et à les traiter conformément à la Politique de
                confidentialité, disponible à l’adresse suivante :{" "}
                <a href="https://www.ablv.swiss/fr/politique-de-confidentialite/">
                  ablv.swiss/fr/politique-de-confidentialite
                </a>
              </p>
              <h2>6. Responsabilité des visiteurs du Site</h2>
              <p>
                Vous vous engagez à ne violer aucune norme légale ni à utiliser
                le Site dans un but illicite ou autre que celui pour lequel il
                vous est proposé.
              </p>
              <p>
                Vous vous engagez également à nous indemniser et à nous dégager
                de toute responsabilité, de même que nos employés, contre toute
                réclamation, coût, dommage, et dépense (y compris les honoraires
                d’avocats et frais de justice) résultant de (i) votre
                utilisation du Site ; (ii) votre non-respect ou violation de
                toute disposition des CGU et (iii) tout autre cause entraînant
                un dommage pour nous.
              </p>
              <p>
                Le Site peut contenir des liens hypertextes renvoyant vers
                d’autres sites web. Vous visitez ces sites sous votre seule et
                entière responsabilité. Nous ne pouvons en aucun cas être tenus
                pour responsable du contenu de ces sites tiers, dans l’hypothèse
                où vous subiriez un dommage de ce fait.
              </p>
              <h2>7. Exclusion de responsabilité et de garantie</h2>
              <p>
                Vous utilisez le Site à vos risques exclusifs. Nous nous
                efforcerons d’assurer le fonctionnement fiable et durable du
                Site, mais ne garantissons pas que celui-ci est à jour,
                disponible, conforme à vos besoins ou exempt d’erreurs, de
                défaillances ou de virus. Nous ne répondons pas non plus des
                conséquences résultant d’un accès restreint ou dégradé à
                Internet.
              </p>
              <p>
                Dans les limites permises par la loi, nous déclinons toute
                responsabilité pour tout dommage direct ou indirect ou perte lié
                à l’utilisation ou l’impossibilité d’utiliser le Site ou son
                contenu.
              </p>
              <p>
                Si vous avez besoin d’accéder au Site avant une certaine
                échéance, vous devez prendre en compte les risques
                d’indisponibilité et êtes seul responsable de prendre les
                mesures nécessaires pour le faire suffisamment tôt.
              </p>
              <h2>8. Propriété intellectuelle</h2>
              <p>
                Le Site, ainsi que son contenu, est protégé par la législation
                relative à la propriété intellectuelle, en particulier par le
                droit d’auteur et le droit des marques.
              </p>
              <p>
                Les présentes CGU ne vous accordent aucun droit, titre ou
                intérêt à l’égard du Site, des droits de tiers, des marques
                commerciales, des logos et d’autres éléments d’identité de notre
                société.
              </p>
              <p>
                Nous vous accordons une licence limitée, non exclusive,
                non-transférable et révocable en tout temps d’utiliser le Site
                pour vos besoins personnels, de même que pour afficher le
                contenu du Site sur votre écran d’ordinateur, smartphone ou
                tablette, à la condition de votre respect des présentes CGU.
                Toute autre utilisation est exclue sans notre accord écrit
                préalable.
              </p>
              <h2>9. For et droit applicable</h2>
              <p>
                Les présentes CGU, de même que toutes les questions qui en
                découlent ou qui sont en relation avec elles seront soumises au
                droit suisse, à l’exclusion des règles en matière de conflit de
                lois.
              </p>
              <p>
                Toute prétention et tout différend en lien avec ces CGU seront
                sujets à la juridiction exclusive des Tribunaux du siège de ABLV
                Group SA.
              </p>
              <h2>10. Contact</h2>
              <p>
                Pour toute question relative aux CGU, ainsi que pour signaler
                toute violation, veuillez nous contacter : ABLV Group SA, rue
                des Jordils 40, 1025 St-Sulpice.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Tos
